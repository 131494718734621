import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.parse-int.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.pad-start.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/web.dom-collections.iterator.js";
import address from '@/static/json/uview2.0';
import { qsStringify, debounce, dateFormat } from '@/scripts/utils';
import { encrypt, decrypt } from '@/scripts/secret/aes.js';
// 设置手机号的验证规则
const validateMobile = function validateMobile(rule, value, callback) {
  let newValue = value.replace(/[^0-9]/gi, '');
  if (value !== newValue) {
    callback(new Error('请输入正确的手机号'));
  } else if (newValue.length !== 11) {
    callback(new Error('请输入正确的手机号'));
  } else {
    callback();
  }
};
export default {
  data() {
    // 设置手机号的验证规则
    const validateMobile = function validateMobile(rule, value, callback) {
      let newValue = value.replace(/[^0-9]/gi, '');
      if (value !== newValue) {
        callback(new Error('请输入正确的手机号'));
      } else if (newValue.length !== 11) {
        callback(new Error('请输入正确的手机号'));
      } else {
        callback();
      }
    };
    return {
      animationKey: 0,
      //手动dom元素动画效果
      showPhoneLogOff: false,
      //是否展示手机号注销
      showWechatLogOff: false,
      //是否展示微信扫码注销
      showWechatLogOffCodeMask: false,
      // 是否展示微信扫码注销遮罩层
      checkAgreement: false,
      //是否同意注销协议
      showLogOff: false,
      //展示注销账号协议
      bindCountdown: 0,
      //手机号验证码登录的表单验证规则
      codeRules: {
        phoneNumber: [{
          required: true,
          trigger: 'blur',
          validator: validateMobile
        } //写入验证器
        ],
        code: [{
          required: true,
          trigger: 'blur',
          len: 6,
          pattern: /^[0-9]{6}$/,
          message: '请输入六位数字验证码'
        } //六位数字验证码的验证规则
        ]
      },
      codeRulesLogOff: {
        phoneNumber: [{
          required: true,
          trigger: 'blur',
          validator: validateMobile
        } //写入验证器
        ],
        code: [{
          required: true,
          trigger: 'blur',
          len: 6,
          pattern: /^[0-9]{6}$/,
          message: '请输入六位数字验证码'
        } //六位数字验证码的验证规则
        ]
      },
      codeForm: {
        phoneNumber: '',
        code: ''
      },
      codeFormLogOff: {
        phoneNumber: '',
        code: ''
      },
      timerForMask: '',
      //五分钟更新一次二维码
      timerForCode: '',
      // 微信扫码登录轮询更新状态计时器
      uuid: '',
      // 用户唯一标识
      showWechatCodeMask: false,
      // 微信登录二维码是否显示遮罩层
      wechatQrcode: '',
      // 微信登录二维码
      wechatLogOffQrcode: '',
      //微信注销二维码
      isBindMobile: false,
      //是否展示绑定手机号弹框
      isBindWechat: false,
      //是否展示微信扫码绑定弹框
      inputTimer: null,
      //防止用户更改名称过于频繁发送请求的计时器
      sys_user_sex: [],
      sys_user_is_bind_mobile: [],
      sys_user_is_bind_wechat: [],
      goods_list_status: [],
      //产品状态 有效无效
      showLoading: false,
      //是否展示加载状态
      pagemsg: {
        page: 1,
        per_page: 10
      },
      total: 0,
      total_pages: 0,
      count: 20,
      showOldPassword: false,
      isLoading: false,
      countdown: 0,
      // 倒计时
      timer: null,
      // 定时器
      localStorageKey: 'verificationCodeCountdown',
      // 存储在localStorage中的键名
      options2: address,
      //省市区的数据
      dialogVisible: false,
      //控制订单详情弹出层
      orderList: [],
      //订单列表
      userInfo: null,
      activeIndex: '1-1',
      //用户信息表单
      informationForm: {
        name: '',
        id: '',
        sex: '',
        birthday: '',
        addresss: [],
        phone: ''
      },
      //用户信息表单验证
      // informationFormRules: {
      //   name: [
      //     {
      //       required:true,
      //       trigger: 'blur',
      //       message: '最少三位最多八位',
      //       min: 3,
      //       max: 20,
      //     },
      //   ],
      // },
      //修改密码表单
      passwordForm: {
        oldPassword: '',
        newPassword: '',
        determineNewPassword: ''
      },
      //修改密码表单验证
      changePasswordFormRules: {
        newPassword: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }, {
          min: 6,
          max: 20,
          message: '密码长度在 6 到 20 个字符',
          trigger: 'blur'
        }],
        determineNewPassword: [{
          required: true,
          message: '请再次输入密码',
          trigger: 'blur'
        }, {
          validator: this.validateConfirmPassword,
          trigger: 'blur'
        }],
        oldPassword: [{
          required: false,
          message: '请输入旧密码',
          trigger: 'blur'
        }, {
          min: 6,
          max: 20,
          message: '密码长度在 6 到 20 个字符',
          trigger: 'blur'
        }]
      },
      //忘记密码的表单
      forgetPasswordForm: {
        forgetPasswordPhoneNumber: '',
        forgetPasswordCode: '',
        forgetPasswordPassword: '',
        determineForgetPasswordPassword: ''
      },
      //忘记密码的表单验证
      forgetPasswordFormRules: {
        forgetPasswordPhoneNumber: [{
          required: true,
          trigger: 'blur',
          validator: validateMobile
        } //写入验证器
        ],
        forgetPasswordCode: [{
          required: true,
          trigger: 'blur',
          len: 6,
          pattern: /^[0-9]{6}$/,
          message: '请输入六位数字验证码'
        } //写入验证器
        ],
        forgetPasswordPassword: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }, {
          min: 6,
          max: 20,
          message: '密码长度在 6 到 20 个字符',
          trigger: 'blur'
        }],
        determineForgetPasswordPassword: [{
          required: true,
          message: '请再次输入密码',
          trigger: 'blur'
        }, {
          validator: this.validateForgetPasswordConfirmPassword,
          trigger: 'blur'
        }]
      },
      //性别选择数据
      options: [],
      //省市区的触发方法
      cascaderProps: {
        expandTrigger: 'hover',
        value: 'value',
        label: 'label',
        children: 'children'
      },
      //订单弹出层的数据对象
      orderPopObject: {
        orderId: '',
        price: '',
        date: '',
        status: '',
        goods_id_value: '',
        expiration_time: ''
      }
    };
  },
  methods: {
    //点击注销的下一步
    next() {
      if (this.checkAgreement) {
        //如果没有绑定手机号
        if (this.userInfo.is_bind_mobile == 2) {
          //隐藏注销协议
          this.showLogOff = false;
          //展示微信注销的二维码
          this.showPhoneLogOff = true;
          // this.codeForm.phoneNumber = this.userInfo.mobile.substring(0,3)+'****'+ this.userInfo.mobile.substring(7)
        } else {
          //没有绑定微信
          //隐藏注销协议
          this.showLogOff = false;
          //展示微信注销的二维码
          this.showWechatLogOff = true;
          //获取微信扫码注销二维码
          this.getWechatQrCodeLogOff(true);
        }
      } else {
        this.animationKey += 1;
      }
    },
    //注销账号
    logOff() {
      if (this.showWechatLogOff) {
        //微信扫码注销
        const data = {
          uuid: this.uuid,
          type: 2
        };
        this.$common.logoff(data).then(res => {
          if (res.data.status_code == 200) {
            if (this.userInfo.is_bind_mobile == 1 && this.userInfo.is_bind_wechat == 1) {
              localStorage.removeItem('Authorization');
              localStorage.removeItem('userInfo');
              this.$router.push('/login');
            } else {
              this.showWechatLogOff = false;
              this.$message.success('注销成功您现在可以扫码重新绑定您的微信号');
              this.showBindWechat();
            }
          } else if (res.data.status_code == 201) {} else {
            clearInterval(this.timerForCode);
            clearTimeout(this.timerForMask);
            this.timerForCode = null;
            this.timerForMask = null;
            this.showWechatLogOffCodeMask = true;
          }
        }).catch(err => {
          clearInterval(this.timerForCode);
          clearTimeout(this.timerForMask);
          this.timerForCode = null;
          this.timerForMask = null;
          // this.showWechatLogOffCodeMask = true
          this.showWechatLogOff = false;
        });
      } else if (this.showPhoneLogOff) {
        //手机号注销
        this.$refs.codeFormLogOff.validate(valid => {
          if (valid) {
            const data = {
              mobile: this.codeFormLogOff.phoneNumber,
              code: this.codeFormLogOff.code,
              type: 1
            };
            this.$common.logoff(data).then(res => {
              if (res.data.status_code == 200) {
                this.$common.getUserInfo().then(res => {
                  this.showPhoneLogOff = false;
                  this.$message.success('注销成功您现在可以重新绑定您的手机号');
                  this.isBindMobile = true;
                  if (this.userInfo.is_bind_mobile == 2 && this.userInfo.is_bind_wechat == 2) {
                    localStorage.removeItem('Authorization');
                    localStorage.removeItem('userInfo');
                    this.$router.push('/login');
                  }
                });
              }
            }).catch(() => {
              this.isBindMobile = false;
            });
          } else {}
        });
      }
    },
    //获取微信扫码注销二维码(check用来手动控制第一次showWechatCodeMask为false时启动函数)
    getWechatQrCodeLogOff(check = false) {
      // 获取二维码 uuid
      if (this.showWechatLogOffCodeMask || check) {
        clearInterval(this.timerForCode);
        clearTimeout(this.timerForMask);
        this.showWechatLogOffCodeMask = false;
        this.$common.getWechatQrCode({
          'type': 3
        }).then(res => {
          console.log(res.data.data, '微信二维码');
          this.wechatLogOffQrcode = res.data.data.qrcode_url;
          this.uuid = res.data.data.uuid;

          // 只有弹出绑定微信二维码的时候才会查询登录轮询
          if (this.showWechatLogOff) {
            this.timerForCode = setInterval(() => {
              if (this.showWechatLogOff) {
                this.logOff(); // 确保正确调用
              }
            }, 3000);
          }
          // 查询是否到时失效
          this.timerForMask = setTimeout(() => {
            this.showWechatLogOffCodeMask = true;
            //二维码失效停止验证登录轮询
            clearInterval(this.timerForCode);
            // this.$message.success('打的计时器')
          }, 5 * 60 * 1000);
        });
      }
    },
    //展示注销协议
    showLogOffAgreement() {
      this.showLogOff = true;
    },
    //实现点击复制的方法
    copyToClipboard() {
      const input = document.createElement('input');
      input.value = this.informationForm.id;
      document.body.appendChild(input);
      input.select();
      document.execCommand('copy');
      document.body.removeChild(input);
      // console.log('复制成功');
      this.$message.success('复制成功');
    },
    //获取微信登录二维码(check用来手动控制第一次showWechatCodeMask为false时启动函数)
    getWechatQrCode(check = false) {
      // 获取二维码 uuid
      if (this.showWechatCodeMask || check) {
        clearInterval(this.timerForCode);
        clearTimeout(this.timerForMask);
        this.showWechatCodeMask = false;
        this.$common.getWechatQrCode({
          'type': 2
        }).then(res => {
          console.log(res.data.data, '微信二维码');
          this.wechatQrcode = res.data.data.qrcode_url;
          this.uuid = res.data.data.uuid;

          // 只有弹出绑定微信二维码的时候才会查询登录轮询
          if (this.isBindWechat) {
            this.timerForCode = setInterval(() => {
              if (this.isBindWechat) {
                this.bindWechat(); // 确保正确调用
              }
            }, 3000);
          }
          // 查询是否到时失效
          this.timerForMask = setTimeout(() => {
            this.showWechatCodeMask = true;
            //二维码失效停止验证登录轮询
            clearInterval(this.timerForCode);
            // this.$message.success('打的计时器')
          }, 5 * 60 * 1000);
        });
      }
    },
    //展示绑定微信
    showBindWechat() {
      if (this.userInfo.is_bind_wechat == 1) return false;
      this.isBindWechat = true;
      //获取微信登录二维码
      this.getWechatQrCode(true);
    },
    //绑定微信
    bindWechat() {
      const data = {
        uuid: this.uuid,
        type: 2
      };
      this.$common.bindAccount(data).then(res => {
        // this.$message.success('绑定成功');
        if (res.data.status_code == 200) {
          this.$common.getUserInfo().then(res => {
            let result = res.data.data;
            this.userInfo = result;
            result.sex = result.sex.toString();
            const jsonString = JSON.stringify(result);
            localStorage.setItem('userInfo', jsonString);
          });
          this.$message.success('绑定成功');
          this.isBindWechat = false;
        } else if (res.data.status_code == 201) {} else {
          clearInterval(this.timerForCode);
          clearTimeout(this.timerForMask);
          this.timerForCode = null;
          this.timerForMask = null;
          this.showWechatCodeMask = true;
        }
      }).catch(err => {
        clearInterval(this.timerForCode);
        clearTimeout(this.timerForMask);
        this.timerForCode = null;
        this.timerForMask = null;
        this.showWechatCodeMask = true;
      });
    },
    //展示绑定手机号
    showBindMobile() {
      if (this.userInfo.is_bind_mobile == 1) return false;
      this.isBindMobile = true;
    },
    //绑定手机号
    bindMobile() {
      this.$refs.codeForm.validate(valid => {
        if (valid) {
          const data = {
            mobile: this.codeForm.phoneNumber,
            code: this.codeForm.code,
            type: 1
          };
          this.$common.bindAccount(data).then(res => {
            // this.$message.success('绑定成功');
            if (res.data.status_code == 200) {
              this.$common.getUserInfo().then(res => {
                let result = res.data.data;
                this.userInfo = result;
                result.sex = result.sex.toString();
                const jsonString = JSON.stringify(result);
                localStorage.setItem('userInfo', jsonString);
              });
              this.$message.success('绑定成功');
              this.isBindMobile = false;
            }
          });
        } else {}
      });
    },
    //处理昵称输出
    handleInput() {
      console.log(this.informationForm.name, this.informationForm.name.length);
      this.$forceUpdate();
      clearTimeout(this.inputTimer);
      if (this.informationForm.name.length < 4) return;
      // 设置新的定时器，延迟提交事件
      this.inputTimer = setTimeout(() => {
        this.submitInformationForm();
      }, 1500); // 设置延时时间，单位是毫秒
    },
    handleSexChange(value) {
      // 监听下拉框值的变化，并更新信息表单中的 sex 字段
      this.informationForm.sex = value;
      console.log(value, '下拉框的值');
      console.log(this.informationForm.sex, '下拉框的值');
      console.log(this.options);
    },
    //获取字典列表
    selectDictList(array) {
      this.$common.selectDictList(array).then(res => {
        console.log(res.data.data);
        for (let j = 0; j < array.length; j++) {
          console.log(array[j]);
          for (let i = 0; i < res.data.data[array[j]].length; i++) {
            this[array[j]].push({
              value: res.data.data[array[j]][i].dict_value,
              label: res.data.data[array[j]][i].dict_label
            });
          }
        }
        console.log(this.options);
      });
    },
    handleOpen(key, keyPath) {
      // console.log(key, keyPath)
      //   var allEle = document.querySelectorAll('.el-submenu')
      //   console.log(allEle)
      //   for (let i = 0; i < allEle.length; i++) {
      //     var iconF = allEle[i].querySelector('.el-icon-user')
      //     var titleF = allEle[i].querySelector('.title')
      //     iconF.style.color = 'black'
      //     titleF.style.color = 'black'
      //   }
      //   var icon = allEle[key[0] - 1].querySelector('.el-icon-user')
      //   var title = allEle[key[0] - 1].querySelector('.title')
      //   icon.style.color = '#1BB7F9'
      //   title.style.color = '#1BB7F9'
    },
    //左边nav被关闭时触发
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    //处理省市区被选中的返回值
    handleRegionChange(value) {
      console.log(value, '原始值', this.informationForm.addresss);
      // 把数组里面的数字值全转换为字符串
      for (let i = 0; i < value.length; i++) {
        value[i] = value[i].toString();
      }
      console.log(value, '转换后的值');
      this.informationForm.addresss = value;
      // const regionNames = this.findRegionNames(value)
      // console.log('Selected Region:', regionNames.join('/'))
      this.submitInformationForm();
    },
    //处理省市区数据
    findRegionNames(value) {
      let regionNames = [];
      let options = this.options2;
      while (value.length > 0) {
        let found = false;
        for (let option of options) {
          if (option.value === value[0]) {
            regionNames.push(option.label);
            options = option.children;
            value = value.slice(1);
            found = true;
            break;
          }
        }
        if (!found) {
          break;
        }
      }
      return regionNames;
    },
    //左边某个nav被选中时触发 切换activeIndex实现内容切换
    handleSelect(key, keyPath) {
      this.activeIndex = key;
      console.log(key, this.activeIndex);
      console.log(this.$refs.personalCenter, this.$refs.myOrder);
    },
    //当表格某行被点击时的回调
    handleRowClick(row) {
      console.log(row);
      this.orderPopObject.orderId = row.order_id;
      this.orderPopObject.price = row.amount;
      this.orderPopObject.date = row.created_at;
      this.orderPopObject.status = row.status;
      this.orderPopObject.goods_id_value = row.goods_id_value;
      this.orderPopObject.expiration_time = row.expiration_time;
      this.dialogVisible = true;
    },
    //当我在订单页面时候点击我的订单
    changeToOrder(activeIndex) {
      if (this.activeIndex == activeIndex) return;
      this.activeIndex = activeIndex;
    },
    //提交个人信息表单
    submitInformationForm() {
      this.$refs.informationForm.validate(valid => {
        this.showLoading = true;
        if (valid) {
          // if (this.informationForm.birthday instanceof Date) {
          //   const dateString = this.informationForm.birthday.toISOString()
          //   this.informationForm.birthday = dateString.slice(0, 10)
          // }
          const data = {
            id: this.informationForm.id,
            mobile: this.informationForm.phone,
            nickname: this.informationForm.name,
            city: this.informationForm.addresss,
            birthday: this.informationForm.birthday,
            sex: this.informationForm.sex
          };
          this.$common.updateUserInfo(data).then(res => {
            // this.$message.success(res.data.message)
            this.$common.getUserInfo().then(res => {
              let result = res.data.data;
              result.sex = result.sex.toString();
              const jsonString = JSON.stringify(result);
              localStorage.setItem('userInfo', jsonString);
              console.log(res.data.data, '用户信息');
            });
          }).finally(() => {
            this.showLoading = false;
          });
        } else {
          // this.$message.error('请确认表单格式正确')
          this.showLoading = false;
        }
      });
      console.log(this.informationForm);
    },
    //重置密码确认密码的逻辑
    validateConfirmPassword(rule, value, callback) {
      if (value != this.passwordForm.newPassword) {
        callback(new Error('两次输入的密码不一致'));
      } else {
        callback();
      }
    },
    //重置密码确认密码的逻辑
    validateForgetPasswordConfirmPassword(rule, value, callback) {
      if (value !== this.forgetPasswordForm.forgetPasswordPassword) {
        callback(new Error('两次输入的密码不一致'));
      } else {
        callback();
      }
    },
    //根据不同状态修改密码1设置密码 2旧密码修改方式 3手机短信方式
    changePassword() {
      const {
        pwd_status
      } = this.userInfo;
      //未设置密码
      if (pwd_status == 2) {
        this.$refs.passwordForm.validate(valid => {
          this.showLoading = true;
          if (valid) {
            const data = {
              type: '1',
              password: encrypt(this.passwordForm.newPassword),
              password_confirmation: encrypt(this.passwordForm.determineNewPassword)
            };
            //设置密码
            this.$common.resetPassword(data).then(res => {
              console.log(res, '设置密码的信息');
              this.$message.success(res.data.message);
              this.showOldPassword = true;

              //清空表单
              this.$refs.passwordForm.resetFields();
              //更新用户信息
              this.$common.getUserInfo().then(res => {
                const jsonString = JSON.stringify(res.data.data);
                localStorage.setItem('userInfo', jsonString);
                this.userInfo = res.data.data;
                console.log(res.data.data, '获取修改密码后的用户信息');
              });
            }).finally(() => {
              this.showLoading = false;
            });
          } else {
            this.showLoading = false;
          }
        });
      } else if (pwd_status == 1) {
        this.$refs.passwordForm.validate(valid => {
          this.showLoading = true;
          if (valid) {
            const data = {
              type: '2',
              password: encrypt(this.passwordForm.newPassword),
              password_confirmation: encrypt(this.passwordForm.determineNewPassword),
              old_password: encrypt(this.passwordForm.oldPassword)
            };
            //设置密码
            this.$common.resetPassword(data).then(res => {
              console.log(res, '旧密码修改的信息');
              this.$message.success(res.data.message);
              //成功之后重新登录
              this.$common.logout().then(res => {
                this.$message.success('请重新登录');
                localStorage.removeItem('Authorization');
                localStorage.removeItem('userInfo');
                this.$router.push('/login');
              });
            }).finally(() => {
              this.showLoading = false;
            });
            //更新用户信息
            // this.$common.getUserInfo().then((res) => {
            //   const jsonString = JSON.stringify(res.data.data)
            //   localStorage.setItem('userInfo', jsonString)
            //   this.userInfo = res.data.data
            //   console.log(res.data.data, '获取修改密码后的用户信息')
            // })
          } else {
            this.showLoading = false;
          }
        });
      }
    },
    //旧密码修改跳转到忘记密码
    ToForgetPassword() {
      this.activeIndex = '1-3';
    },
    //当修改密码的方式是旧密码修改时把旧密码改为必须输入并且显示
    showOldPasswordInput() {
      this.showOldPassword = this.userInfo && this.userInfo.pwd_status == 1;
      if (this.showOldPassword) {
        this.changePasswordFormRules.oldPassword[0].required = true;
      }
    },
    //忘记密码提交
    resetPassword() {
      this.$refs.forgetPasswordForm.validate(valid => {
        this.showLoading = true;
        if (valid) {
          const data = {
            type: '3',
            code: this.forgetPasswordForm.forgetPasswordCode,
            mobile: this.forgetPasswordForm.forgetPasswordPhoneNumber,
            password: encrypt(this.forgetPasswordForm.forgetPasswordPassword),
            password_confirmation: encrypt(this.forgetPasswordForm.determineForgetPasswordPassword)
          };
          //设置密码
          this.$common.resetPassword(data).then(res => {
            console.log(res, '忘记密码的信息');
            this.$message.success(res.data.message);
          }).finally(() => {
            this.showLoading = false;
          });
          //更新用户信息
          // this.$common.getUserInfo().then((res) => {
          //   const jsonString = JSON.stringify(res.data.data)
          //   localStorage.setItem('userInfo', jsonString)
          //   this.userInfo = res.data.data
          //   console.log(res.data.data, '获取修改密码后的用户信息')
          // })
        } else {}
        this.showLoading = false;
      });
    },
    //发送验证码的请求封装
    simulateSendVerificationCode(transData) {
      if (this.showPhoneLogOff) {
        const data = qsStringify({
          mobile: this.codeFormLogOff.phoneNumber,
          type: 3
        });
        this.$common.registerSendCode(data).then(res => {
          console.log('我是哦结果', res.data);
          this.$message.success('验证码发送成功');
        }).catch(error => {
          clearInterval(this.timer);
          //处理时间戳
          this.countdown = 0;
          const endTimestamp = Date.now() + this.countdown * 1000;
          localStorage.setItem(this.localStorageKey, endTimestamp.toString());
        });
      } else if (this.activeIndex == '1-3') {
        const data = qsStringify({
          mobile: transData,
          type: 4
        });
        this.$common.registerSendCode(data).then(res => {
          console.log('我是哦结果', res.data);
          this.$message.success('验证码发送成功');
        }).catch(error => {
          clearInterval(this.timer);
          //处理时间戳
          this.countdown = 0;
          const endTimestamp = Date.now() + this.countdown * 1000;
          localStorage.setItem(this.localStorageKey, endTimestamp.toString());
        });
      } else {
        //数据转换成www的格式
        const data = qsStringify({
          mobile: transData,
          type: 2
        });
        this.$common.registerSendCode(data).then(res => {
          console.log('我是哦结果', res.data);
          this.$message.success('验证码发送成功');
        }).catch(error => {
          clearInterval(this.timer);
          //处理时间戳
          this.countdown = 0;
          const endTimestamp = Date.now() + this.countdown * 1000;
          localStorage.setItem(this.localStorageKey, endTimestamp.toString());
        });
      }
    },
    //处理验证码逻辑的封装
    sendVerificationCode(formRefName, veri, phoneNumber) {
      console.log(formRefName, '123', phoneNumber);
      const formRef = this.$refs[formRefName];
      // 验证指定的表单项
      formRef.validateField(veri, errorMsg => {
        if (errorMsg) {
          // 表单项验证失败
          console.log('失败');
        } else {
          // console.log('手机号',phoneNumber)
          // console.log('成功')
          // 表单项验证通过，执行提交操作
          // 检查localStorage中是否有存储的倒计时结束时间戳
          const storedTimestamp = localStorage.getItem(this.localStorageKey);
          console.log(storedTimestamp, !storedTimestamp);
          if (!storedTimestamp || parseInt(storedTimestamp, 10) < Date.now() || !storedTimestamp.trim()) {
            // 模拟发送验证码的异步操作
            // 在实际应用中，这里应该是发送请求给后端，后端发送验证码，等待成功后再开始倒计时
            this.simulateSendVerificationCode(phoneNumber);

            // 设置倒计时为60秒
            this.countdown = 60;

            // 存储倒计时结束的时间戳到localStorage
            const endTimestamp = Date.now() + this.countdown * 1000;
            localStorage.setItem(this.localStorageKey, endTimestamp.toString());

            // 启动定时器，每秒减少倒计时
            this.timer = setInterval(() => {
              if (this.countdown > 0) {
                this.countdown--;
              } else {
                // 倒计时结束，清除定时器
                clearInterval(this.timer);
              }
            }, 1000);
          } else {
            // 如果倒计时还未结束，不执行发送逻辑
            // console.log('请等待倒计时结束后再发送验证码')
            this.$message.error('您发送请求过于频繁请一分钟后再试');
          }
        }
      });
    },
    //获取用户信息并且把个人信息回显
    getUserInfo() {
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
      console.log(this.userInfo.sex, 'this.userInfo.sex');
      const {
        id,
        sex,
        birthday
      } = this.userInfo;
      this.informationForm = {
        id,
        birthday,
        sex
      };
      this.informationForm.name = this.userInfo.nickname;
      this.informationForm.phone = this.userInfo.mobile;
      if (this.userInfo.city) {
        for (let i = 0; i < this.userInfo.city.length; i++) {
          this.userInfo.city[i] = Number(this.userInfo.city[i]);
        }
      }
      this.informationForm.addresss = this.userInfo.city;
    },
    //获取订单列表
    selectOrderList() {
      this.$common.selectOrderList(this.pagemsg).then(res => {
        this.total = res.data.data.total;
        this.total_pages = res.data.data.total_pages;
        this.pagemsg.page += 1;
        console.log(res.data);
        this.orderList = res.data.data.rows.concat(this.orderList);
        // for (let i = 0; i < this.orderList.length; i++) {
        //   this.orderList[i].create_at = dateFormat(
        //     this.orderList[i].created_at * 1000,
        //     '@iso',
        //   )
        //     .replace('T', ' ')
        //     .slice(0, 19)
        //   this.orderList[i].type =
        //     this.orderList[i].type == 1 || this.orderList[i].type == '待付款'
        //       ? '待付款'
        //       : this.orderList[i].type == 3 ||
        //         this.orderList[i].type == '已退款'
        //       ? '已退款'
        //       : '已付款'
        // }
      });
    },
    //无限滚动表格添加数据
    load() {
      // 在实际应用中，这里应该是从后端获取数据的逻辑
      // setTimeout(() => {
      // 模拟从后端获取的新数据
      if (this.pagemsg.page > this.total_pages) return;
      this.selectOrderList();

      // 将新数据追加到表格数据中
      // this.orderList = this.orderList.concat(newData)

      // 模拟更新总条数
      // this.total = 100

      this.isLoading = false;
      this.changeStyle();
      // }, 1000)
    },
    //处理滚动条上弹
    handleScroll() {
      if (this.pagemsg.page > this.total_pages) return;
      // 在滚动时检查是否滚动到了底部，以及是否有新数据加载
      const container = this.$refs.scrollContainer;
      const atBottom = container.scrollTop + container.clientHeight >= container.scrollHeight;
      if (atBottom && !this.isLoading) {
        // 加载更多数据
        this.load();

        // 在加载完数据后，将滚动位置往上调整一些距离
        this.$nextTick(() => {
          container.scrollTop = container.scrollTop - 250; // 调整的距离可以根据实际情况调整
        });
      }
    },
    //获取表格每行改变字体颜色以及其它样式
    changeStyle() {
      this.$nextTick(() => {
        let tableRows = document.querySelectorAll('.el-table__body-wrapper tr');
        tableRows.forEach(row => {
          let cells = row.querySelectorAll('td:last-child');
          let lastCell = cells[0];
          if (lastCell) {
            let textContent = lastCell.textContent.trim();
            if (textContent == '有效' || textContent == '2') {
              lastCell.style.color = 'red';
              lastCell.style.fontWeight = 'bold';
            } else if (textContent == '无效' || textContent == '1') {
              lastCell.style.color = '#999';
              lastCell.style.fontWeight = 'bold';
            } else if (textContent == '已退款' || textContent == '3') {
              lastCell.style.color = '#00FF00';
              lastCell.style.fontWeight = 'bold';
            }
          }
        });
      });
    }
  },
  beforeUpdate() {
    // 防止表格抖动
    this.$nextTick(() => {
      this.$refs.orderTable.doLayout();
    });
  },
  mounted() {
    // function convertValueType(obj, targetKey, targetType) {
    //   if (Object.prototype.hasOwnProperty.call(obj, targetKey)) {
    //     obj[targetKey] = targetType(obj[targetKey])
    //   }
    //   if (Array.isArray(obj.children)) {
    //     obj.children.forEach((child) =>
    //       convertValueType(child, targetKey, targetType),
    //     )
    //   }
    // }

    // address.forEach((obj) => convertValueType(obj, 'value', String))

    // console.log(address)

    // var allEle = document.querySelectorAll('.el-submenu')
    // var icon = allEle[0].querySelector('.el-icon-user')
    // var title = allEle[0].querySelector('.title')
    // icon.style.color = '#1BB7F9'
    // title.style.color = '#1BB7F9'
    // 获取table的所有行
    this.changeStyle();
    this.resetPassword = debounce(this.resetPassword, 1000);
    this.changePassword = debounce(this.changePassword, 1000);
    this.bindMobile = debounce(this.bindMobile, 1000);
    this.copyToClipboard = debounce(this.copyToClipboard, 1000);
    // this.submitInformationForm = debounce(this.submitInformationForm, 1000)
  },
  created() {
    //获取用户信息并且把个人信息回显
    this.getUserInfo();

    //获取订单列表
    this.selectOrderList();

    //当修改密码的方式是旧密码修改时把久密码改为必须输入并且显示
    this.showOldPasswordInput();

    //获取字典列表
    this.selectDictList(['sys_user_sex', 'sys_user_is_bind_wechat', 'sys_user_is_bind_mobile', 'goods_list_status']);

    //头部导航栏点进来
    if (!this.$route.query.activeIndex) return;
    this.activeIndex = this.$route.query.activeIndex;
    const storedTimestamp = localStorage.getItem(this.localStorageKey);
    if (storedTimestamp && parseInt(storedTimestamp, 10) > Date.now()) {
      this.countdown = Math.ceil((parseInt(storedTimestamp, 10) - Date.now()) / 1000);

      // 启动定时器，每秒减少倒计时
      this.timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          // 倒计时结束，清除定时器
          clearInterval(this.timer);
        }
      }, 1000);
    }
  },
  beforeDestroy() {
    clearInterval(this.timerForCode);
    clearTimeout(this.timerForMask);
    this.timerForCode = null;
    this.timerForMask = null;
  },
  watch: {
    //忘记密码发送验证码变蓝
    forgetPasswordForm: {
      handler(newVal, oldVal) {
        this.$refs.forgetPasswordForm.validateField('forgetPasswordPhoneNumber', errorMsg => {
          if (errorMsg) {
            // 表单项验证失败
            console.log('失败');
            this.$el.querySelector('.forgetPasswordCode').style.color = '#cccccc';
          } else {
            this.$el.querySelector('.forgetPasswordCode').style.color = '#0da3e2';
          }
        });
      },
      deep: true
    },
    orderList: {
      handler(newVal, oldVal) {
        this.changeStyle();
      },
      deep: true
    },
    informationForm: {
      handler(newVal, oldVal) {
        this.submitInformationForm();
      },
      deep: true
    },
    //手机号验证码登录发送验证码变蓝
    codeForm: {
      handler(newVal, oldVal) {
        this.$refs.codeForm.validateField('phoneNumber', errorMsg => {
          if (errorMsg) {
            // 表单项验证失败
            console.log('失败');
            this.$el.querySelector('.phoneLoginCode').style.color = '#cccccc';
          } else {
            this.$el.querySelector('.phoneLoginCode').style.color = '#0da3e2';
          }
        });
      },
      deep: true
    },
    //注销手机号验证码
    codeFormLogOff: {
      handler(newVal, oldVal) {
        this.$refs.codeFormLogOff.validateField('phoneNumber', errorMsg => {
          if (errorMsg) {
            // 表单项验证失败
            console.log('失败');
            this.$el.querySelector('.logOffCode').style.color = '#cccccc';
          } else {
            this.$el.querySelector('.logOffCode').style.color = '#0da3e2';
          }
        });
      },
      deep: true
    }
  },
  filters: {
    formatTimestamp(timestamp) {
      //   console.log('我是传入的日期'.timestamp)
      // 将时间戳转换为日期对象
      const date = new Date(timestamp * 1000);

      // 获取年、月、日、时、分、秒
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // 补零
      const day = String(date.getDate()).padStart(2, '0'); // 补零
      const hours = String(date.getHours()).padStart(2, '0'); // 补零
      const minutes = String(date.getMinutes()).padStart(2, '0'); // 补零
      const seconds = String(date.getSeconds()).padStart(2, '0'); // 补零

      // 返回格式化后的字符串
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    //获取天数
    getDays(timestamp) {
      if (timestamp > 0) {
        //一天内
        if (timestamp <= 86400) {
          return 1;
        } else {
          return Math.floor(timestamp / 60 / 60 / 24);
        }
      } else {
        return 0;
      }
    }
  }
};